import { IDataPayloadMetaPagination } from 'src/app/core/http/http.interface';
import { ESortOrder } from '../search-bar/search-bar.interface';

export interface IPaginationConfig {
  routerLink: string;
  fragment: string;
  linkParams: {
    first: IStrapiPaginationQueryParams;
    previous: IStrapiPaginationQueryParams | undefined;
    next: IStrapiPaginationQueryParams | undefined;
    last: IStrapiPaginationQueryParams;
  },
  meta: IDataPayloadMetaPagination;
}

export interface IStrapiPaginationQueryParams {
  'pagination[page]'?: number;
  'pagination[pageSize]'?: number;

  'pagination[limit]'?: number;
  'pagination[offset]'?: number;

  'sortOrder'?: ESortOrder;
}

export enum EPaginationType {
  PAGE = 'page',
  OFFSET = 'offset',
}
