import { Injectable, signal } from '@angular/core';
import { IDataPayloadMetaPagination } from 'src/app/core/http/http.interface';
import { EPaginationType } from '../pagination/pagination.interface';
import { ESortOrder, ISearchBarConfig, ISearchResponse } from './search-bar.interface';
import algoliasearch from 'algoliasearch';
import { environment } from 'src/environments/environment';

const searchClient = algoliasearch(environment.algoliaAppId, environment.algoliaApiKey);

@Injectable({
  providedIn: 'root'
})
export class SearchBarService {
  public sortOrder$ = signal(ESortOrder.FEATURED);

  public getSearchBarConfig(
    configObj: ISearchBarConfig,
    meta: IDataPayloadMetaPagination,
    paginationType: EPaginationType,
  ): ISearchBarConfig {
    const payload = paginationType === EPaginationType.PAGE
      ? this._getPagePayload(meta)
      : this._getOffsetPayload(meta);

    return {
      ...configObj,
      ...payload,
    };
  }

  private _getPagePayload(meta: IDataPayloadMetaPagination): Partial<ISearchBarConfig> {
    const { page, pageSize, total } = meta;

    return {
      from: (page! * pageSize!) - pageSize! === 0
        ? 1
        : (page! * pageSize!) - pageSize!,

      to: (page! * pageSize!) > total
        ? total
        : page! * pageSize!,

      totalCount: total,
    }
  }

  private _getOffsetPayload(meta: IDataPayloadMetaPagination): Partial<ISearchBarConfig> {
    const { offset, limit, total } = meta;

    return {
      from: offset! + 1,

      to: (offset! + limit!) > total
        ? total
        : offset! + limit!,

      totalCount: total,
    }
  }

  public async globalSearch(query: string | null | undefined, hitsPerPage: number = 5): Promise<ISearchResponse> {
    const baseRequest = { query, params: { hitsPerPage } };
    const results = await searchClient.multipleQueries([
      {
        indexName: 'blog',
        ...baseRequest,
      },
      {
        indexName: 'outreach',
        ...baseRequest,
      },
      {
        indexName: 'content',
        ...baseRequest,
      },
      {
        indexName: 'military',
        ...baseRequest,
        ...{ params: { hitsPerPage: 10 } },
      },
      {
        indexName: 'industry',
        ...baseRequest,
        ...{ params: { hitsPerPage: 10 } },
      },
    ] as any);

    return (results as ISearchResponse);
  };
}
